<div
  #wrapperElementTemplate
  *ngIf="isVisible"
  class="wrapper"
  [ngClass]="{
    'is-active-onboarding-step': isOnboardingV2Enabled && (currentActiveStepSelector$ | async) === 1
  }"
  appTooltip
  [appTooltipIsShown]="
    (currentActiveStepSelector$ | async) === (isOnboardingV2Enabled ? 1 : 2) &&
    wrapperElementTemplateRef !== undefined
  "
  [appTooltipCaretConfig]="{
    hasCaret: true,
    height: 37,
    width: 37,
    positionOnOrigin: 'top',
    placementX: 'center',
    color: 'white',
    offsetFromHostElement: 21
  }"
  [appTooltipInjectionPayload]="{
    componentToInject: tooltipComponentRef,
    data: {
      currentStep: isOnboardingV2Enabled ? 1 : 2,
      uiContent: {
        title: {
          classes: 'heading2--bold',
          styling: {
            'column-gap': '8px'
          },
          sections: [
            {
              text: 'ONBOARDING.STEPS.2.HEADER.a.' + onboardingFeature.rewardType,
              styling: {
                color: '#0F1933'
              }
            },
            {
              text: 'ONBOARDING.STEPS.2.HEADER.b.' + onboardingFeature.rewardType,
              styling: {
                color: '#E39700'
              }
            }
          ]
        },
        body: {
          paragraphs: [
            {
              classes: 'body2--regular',
              styling: {
                'text-align': 'initial'
              },
              sections: []
            }
          ]
        },
        footer: {
          classes: 'body2--medium',
          styling: {
            'justify-content': 'flex-end'
          },
          buttons: [
            {
              action: 'next',
              styling: {
                padding: '16px 48px',
                'column-gap': '8px'
              },
              sections: [
                {
                  type: 'text',
                  content: 'ONBOARDING.STEPS.1.FOOTER.a'
                },
                {
                  type: 'image',
                  content: assetsPath + 'next-step-left-arrow.svg'
                }
              ]
            }
          ]
        }
      }
    }
  }"
  [appTooltipConnectedElement]="wrapperElementTemplateRef"
  [appTooltipAttachedToElement]="wrapperElementTemplate"
  [appTooltipStyling]="{
    'background-color': 'white',
    'border-radius': '10px'
  }"
  [appTooltipOverlayPosition]="{
    positionOnOrigin: 'bottom',
    placementX: 'center'
  }"
  [appTooltipDimensions]="{
    width: '502px'
  }"
  [appTooltipHasBackdrop]="true"
  [appTooltipParentCustomStyling]="{
    padding: 20
  }"
>
  <div class="progress-container">
    <div class="progress-container__right">
      <img
        *ngIf="isMoneyBack"
        class="progress-container__right__treasure"
        src="{{ assetsPath + state.image }}"
      />

      <img
        *ngIf="isBadge"
        class="progress-container__right__badge"
        src="{{ assetsPath + state.image }}"
      />
      <div class="progress-container__right__text">
        <p *ngIf="isMoneyBack" class="text-on-primary heading2--extra-bold">
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.BANNER.TEXT.CASHBACK.a' | translate }}
          <span class="text-secondary-yellow">{{
            'ONBOARDING.ONBOARDING_GOAL_PROGRESS.BANNER.TEXT.CASHBACK.b'
              | translate : { currentCurrency }
          }}</span>
        </p>
        <p *ngIf="isBadge" class="text-on-primary heading2--extra-bold">
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.BANNER.TEXT.BADGE.a' | translate }}
          <span class="text-secondary-yellow">
            {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.BANNER.TEXT.BADGE.b' | translate }}</span
          >
        </p>
        <p class="text-on-primary body1--regular">
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.BANNER.SUBTEXT' | translate }}
        </p>
      </div>
      <img class="progress-container__right__stars" src="{{ assetsPath + 'stars.svg' }}" />
    </div>
    <div class="progress-container__left">
      <div class="progress-container__left__content">
        <p class="text-content-medium caption2--medium">
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.PROGRESS.TEXT' | translate }}
        </p>
        <p class="text-content body2--bold">
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.PROGRESS.SUBTEXT.a' | translate }}
          <span>{{ allGoals }}</span>
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.PROGRESS.SUBTEXT.b' | translate }}
        </p>
        <div class="progress-container__left__bar-container">
          <app-shared-progress-bar
            class="progress-container__left__bar-container__progress"
            [progressScore]="state.progressScore"
          ></app-shared-progress-bar>
          <p
            class="progress-container__left__bar-container__text text-content-medium caption2--medium"
          >
            {{ state.currentScore }}
            {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.PROGRESS.SUBTEXT.c' | translate }}
            {{ state.maxScore }}
          </p>
        </div>
      </div>
      <div
        class="progress-container__left__button"
        #viewOrderingGoalsTemplate
        appTooltip
        [appTooltipIsShown]="
          (currentActiveStepSelector$ | async) === (isOnboardingV2Enabled ? 2 : 3) &&
          wrapperElementTemplateRef !== undefined
        "
        [appTooltipCaretConfig]="{
          hasCaret: true,
          height: 37,
          width: 37,
          positionOnOrigin: 'top',
          placementX: '360px',
          color: 'white',
          offsetFromHostElement: 20
        }"
        [appTooltipInjectionPayload]="{
          componentToInject: tooltipComponentRef,
          data: {
            currentStep: isOnboardingV2Enabled ? 2 : 3,
            uiContent: {
              title: {
                classes: 'heading2--bold',
                styling: {
                  'column-gap': '8px'
                },
                sections: [
                  {
                    text: 'ONBOARDING.STEPS.3.HEADER.a',
                    styling: {
                      color: '#0F1933'
                    }
                  }
                ]
              },
              body: {
                paragraphs: [
                  {
                    classes: 'body2--regular',
                    styling: {
                      'text-align': 'initial'
                    },
                    sections: [
                      {
                        text: 'ONBOARDING.STEPS.3.BODY.a',
                        styling: {
                          'font-weight': '400',
                          color: '#0F1933'
                        }
                      }
                    ]
                  }
                ]
              },
              footer: {
                classes: 'body2--medium',
                styling: {
                  'justify-content': 'flex-end'
                },
                buttons: [
                  {
                    action: 'next',
                    styling: {
                      padding: '16px 48px',
                      'column-gap': '8px'
                    },
                    sections: [
                      {
                        type: 'text',
                        content: 'ONBOARDING.STEPS.3.FOOTER.a'
                      },
                      {
                        type: 'image',
                        content: assetsPath + 'next-step-left-arrow.svg'
                      }
                    ]
                  }
                ]
              }
            }
          }
        }"
        [appTooltipConnectedElement]="wrapperElementTemplateRef"
        [appTooltipAttachedToElement]="viewOrderingGoalsTemplate"
        [appTooltipStyling]="{
          'background-color': 'white',
          'border-radius': '10px'
        }"
        [appTooltipOverlayPosition]="{
          positionOnOrigin: 'bottom',
          placementX: '-10px'
        }"
        [appTooltipDimensions]="{
          'max-width': '502px'
        }"
        [appTooltipHasBackdrop]="true"
        [appTooltipParentCustomStyling]="{
          padding: 10
        }"
      >
        <button
          class="progress-container__left__button__btn"
          (click)="openCurrentGoalProgressCard()"
        >
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS.BUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
